// import PProviderService from '@/services/api/Provider/PProvider'
import RulesService from "@/services/rules";
import PProviderService from '@/services/api/Provider/PProvider'

export default {
  name: "v-view-account",
  data() {
    return {
      busy: false,
      pProvider: {},
    };
  },
  methods: {
    handleFormError() {
      this.$toast.error('El formulario posee errores. Se deben resolver antes de continuar.')
    },

    /**
     * 
     */
    async handleFormReset() {
      this.busy = true
      await PProviderService.getByIdWhitRegions(this.$provider.id).then(this.handleGetByIdSuccess).catch(this.handleGetByIdError)
      this.busy = false
    },

    /**
     *
     */
    async handleFormSubmit() {
      this.busy = true
      await PProviderService.update(this.pProvider).then(this.handleLoginSuccess).catch(this.handleLoginError)
      this.busy = false
    },

    /**
     * 
     */
    handleLoginError(payload) {
      PProviderService.translate(payload).then(this.$toast.error)
    },

    /**
     * 
     */
    async handleLoginSuccess(payload) {
      PProviderService.translate({ response: payload }).then(this.$toast.success)
    },

        /**
     * 
     */
        handleGetByIdError() {
          this.$toast.error('Error al intentar obtener datos de proveedor.')
        },
    
        /**
         * 
         */
        handleGetByIdSuccess(pProvider) {
          this.pProvider = pProvider
        },
  },
  // mounted() {
  //   this.getUserAccount();
  // },
  created() {
    this.handleFormReset();
  },
  props: {
    
    webUrlRules: {
      type: Array,
      default: () => [
        // RulesService.required,
        RulesService.url,
        RulesService.max(120),
      ],
    },
  },
};
